import { CreateCaseEffects } from './create-case/create-case.effects';
import { MaterialComparableCostEffect } from './material-comparable-costs/material-comparable-costs.effects';
import { MaterialCostDetailsEffects } from './material-cost-details/material-cost-details.effects';
import { MaterialSalesOrgEffect } from './material-sales-org/material-sales-org.effects';
import { MaterialStockEffects } from './material-stock/material-stock.effects';
import { PlantMaterialDetailsEffects } from './plant-material-details/plant-material-details.effects';
import { SapPriceDetailsEffects } from './sap-price-details/sap-price-details.effects';

export const ROOT_EFFECTS = [
  CreateCaseEffects,
  MaterialComparableCostEffect,
  MaterialSalesOrgEffect,
  SapPriceDetailsEffects,
  MaterialStockEffects,
  PlantMaterialDetailsEffects,
  MaterialCostDetailsEffects,
];

export { CreateCaseEffects } from './create-case/create-case.effects';
export { MaterialComparableCostEffect } from './material-comparable-costs/material-comparable-costs.effects';
export { MaterialCostDetailsEffects } from './material-cost-details/material-cost-details.effects';
export { MaterialSalesOrgEffect } from './material-sales-org/material-sales-org.effects';
export { MaterialStockEffects } from './material-stock/material-stock.effects';
export { PlantMaterialDetailsEffects } from './plant-material-details/plant-material-details.effects';
export { SapPriceDetailsEffects } from './sap-price-details/sap-price-details.effects';
